.navbar {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    top: 0;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    min-height: 50px;
    margin-bottom: 2rem;
    .navbar-brand {
        //padding: 1rem;
        width: 247px;
        height: 40px;
        margin-left: 3.5rem;
        @include media-breakpoint-up(md) {
            margin-left: 0;
        }
    }
}

.off-canvas aside.sidebar {
    transform: translateX(0)
}

//left panel sidebar
.sidebar[role="navigation"] {
    min-width: 274px;
    width: 274px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
    left: 0;
    display: block;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    padding-top: 5rem;
    background: #2e3b4e;
    transform: translateX(-274px);
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        font-size: .9rem;
        li {
            a {
                display: block;
                line-height: 2rem;
                padding: .5rem 3rem .5rem 1rem;
                color: #fff;
                transition: all .2s ease;
                border-left: 3px solid rgba(150, 204, 232, 0);
                background-color: transparent;
                position: relative;
                cursor: pointer;
                clear: both;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-decoration: none;
                &:hover {
                    border-left-color: #96cce8;
                    color: #96cce8;
                }
            }
        }
        i {
            margin-right: .5rem;
        }
        .sub-menu {
            padding: 1rem;
            display: none;
            font-size: .9rem;
            list-style: none;
            background: rgba(255, 255, 255, 0.05);
            li {
                a {
                    margin: 0 -1rem;
                    padding: .75rem 0 .75rem 3rem;
                    line-height: 1
                }
            }
        }
        .nav-header {
            font-size: .75rem;
            color: #96cce8;
            border-bottom: 1px solid #96cce8;
            list-style: none;
            margin: .75rem 0;
            padding-bottom: .25rem;
            text-transform: uppercase;
        }
        .item-has-children {
            > a {
                position: relative;
                &::after {
                    content: 'keyboard_arrow_down';
                    display: block;
                    position: absolute;
                    top: 50%;
                    bottom: auto;
                    transform: translateY(-50%);
                    transition: all .2s ease;
                    right: .5em;
                    font: normal normal normal 24px/1 "Material Icons";
                }
                &.submenu-open{
                    &::after {
                        content: 'keyboard_arrow_up';
                    }
                }
            }

        }
    }
    &.slide-in {
        transform: translateX(0);
        visibility: visible;
        transition: transform .4s 0s, visibility 0s 0s;
    }
    .no-touch #cd-lateral-nav a:hover {
        color: #fff;
    }
}

@media (min-width: 992px) {
    .sidebar[role="navigation"] {
        transform: translateX(0)
    }
}