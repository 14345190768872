body {
    textarea.form-control:not([rows='1']) {
        border: none;
        border-bottom: 1px solid rgba(0,0,0,0.42);
        border-radius: 0;
        padding: .5rem 0 0 0;
        min-height: 5rem;
        &:hover {
            box-shadow: inset 0 -2px 0 -1px rgba(0,0,0,0.87);
        }
        &:focus {
            box-shadow: inset 0 -2px 0 -1px  $material-color-pink-a200;
            border-color: $material-color-pink-a200;
            outline: 0;
        }
    }
}

