.logincard {
    margin: auto;
    position: relative;
    max-width: 800px;
    height: 100vh;
    //display: none;
    &::before {
        background-image: url('/assets/images/lawyer-bg.jpg');
        background-position: center;
        position: fixed;
        background-size: cover;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .card {
        margin-top: 0;
        .card-body .text-center img {
            margin: 1rem 0 2rem;
            width: 250px;
        }
        .modal-footer {
            border-top: 1px solid #e0e0e0;
        }
    }
    .linkedin {
        background-image: url("/assets/images/Sign-In-Large---Default.png");
        background-size: 215px 41px;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        width: 215px;
        height: 41px;
        &:hover {
            background-image: url("/assets/images/Sign-In-Large---Hover.png");
        }
    }
}

.logincard {
    .login-card, .register-card, .forgot-password-card {
        width: 430px;
        margin: 0 auto;
    }
    .register-card.step2 {
        width: 600px;
    }
}